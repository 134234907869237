import React from 'react';

import Text from 'components/styled/Text';
import { NavHrefButton } from 'components/styled/Button';

import { MakeTuple } from '.';

import {
	CompanySize,
	OwnershipSector,
	OrderState,
	EmploymentType,
	LanguageLevel,
	FormLabel,
	PersonType,
	Medal,
} from 'typing/endpoints';

export const COMPANY_SIZES: CompanySize[] = [
	'START_UP',
	'MICRO',
	'SMALL',
	'MEDIUM',
	'LARGE',
];

export const CompanySizeTextPrimary: Record<CompanySize, string> = {
	START_UP: '1 - 49',
	MICRO: '1 - 9',
	SMALL: '10 - 49',
	MEDIUM: '50 - 249',
	LARGE: '250+',
};

export const CompanySizeTextSecondary: Record<CompanySize, string> = {
	START_UP: 'Start-up',
	MICRO: 'Mikro firma',
	SMALL: 'Malá firma',
	MEDIUM: 'Střední firma',
	LARGE: 'Velká firma',
};

export const OWNERSHIP_SECTORS: OwnershipSector[] = [
	'PUBLIC',
	'NONPROFIT',
	'PRIVATE',
];

export const OwnershipSectorName: Record<OwnershipSector, string> = {
	PUBLIC: 'Veřejný',
	PRIVATE: 'Soukromý',
	NONPROFIT: 'Neziskový',
};

export const ORDER_STATE_TYPES: OrderState[] = [
	'CREATED',
	'WAITING',
	'REJECT',
	'CONFIRMED',
	'CANCELED',
];

export const OrderStateTextPrimary: Record<OrderState, string> = {
	CREATED: 'Vytvořená',
	WAITING: 'Čekající',
	REJECT: 'Zamítnuta',
	CONFIRMED: 'Potvrzená',
	CANCELED: 'Stornována',
};

export const OrderStateTextSecondary: Record<OrderState, string> = {
	CREATED: 'Čeká na sken faktury',
	WAITING: 'Čeká na potvrzení správcem',
	REJECT: 'Zamítnuta správcem',
	CONFIRMED: 'Potvrzena správcem',
	CANCELED: 'Stornována správcem',
};

export const EMPLOYMENT_TYPES: EmploymentType[] = [
	'HPP',
	'DPP_DPC',
	'SHORT',
	'FREELANCE',
	'BP',
	'DP',
	'TRAINEE',
];

export const MEDALS: Medal[] = ['KC_PORTFOLIO'];

export const EmploymentTypeText: Record<EmploymentType, string> = {
	HPP: 'Hlavní pracovní poměr',
	DPP_DPC: 'Odborné brigády (DPP, DPČ)',
	FREELANCE: 'Freelance',
	TRAINEE: 'Trainee / stáž',
	DP: 'Diplomová práce',
	BP: 'Bakalářská práce',
	SHORT: 'Částečný úvazek',
};

export const MedalText: Record<
	Medal,
	{ label: string; description: string; descriptionWithLink: React.ReactNode }
> = {
	KC_PORTFOLIO: {
		label: 'Medaile Kariérního centra',
		description:
			'Získal/a medaili Kariérního centra - úspěšný absolvent Kompetenčního rozhovoru',
		descriptionWithLink: (
			<Text ml={3}>
				Získal/a medaili Kariérního centra - úspěšný absolvent{' '}
				<NavHrefButton
					px={0}
					py={0}
					variant="text"
					href="https://kariernicentrum.vsb.cz/cs/konzultace-a-rozvoj/kompetencni-rozhovor/"
					target="_blank"
				>
					<u>Kompetenčního rozhovoru</u>
				</NavHrefButton>
				.
			</Text>
		),
	},
};

export const SortedEmploymentTexts: Record<EmploymentType, number> = {
	DPP_DPC: 0,
	TRAINEE: 1,
	SHORT: 2,
	HPP: 3,
	BP: 4,
	DP: 5,
	FREELANCE: 6,
};

export const PersonTypeText: Record<PersonType, string> = {
	STUDENT: 'Pouze studenti',
	ALUMNUS: 'Pouze absolventi',
	ADMIN: 'Admin',
	SUPERADMIN: 'Superadmin',
	RECRUITER: 'Zaměstnavatel',
};

export const LANGUAGE_LEVELS = [
	'A1',
	'A2',
	'B1',
	'B2',
	'C1',
	'C2',
] as LanguageLevel[];

export const LanguageLevelDescriptionTitle: Record<LanguageLevel, string> = {
	A1: 'Slabší pasivní znalost cizího jazyka',
	A2: 'Pasivní znalost cizího jazyka',
	B1: 'Znalost cizího jazyka na komunikativní úrovni',
	B2: 'Znalost cizího jazyka na dobré komunikativní úrovni',
	C1: 'Velmi dobrá znalost cizího jazyka',
	C2: 'Výborná znalost cizího jazyka',
};

export const LanguageLevelDescription: Record<LanguageLevel, string> = {
	A1:
		'Úroveň začátečníků, kteří rozumí větám a slovům týkajícím se jejich osoby a jejich okolí. Jsou schopni vést jen velmi jednoduchou a prostou konverzaci s mluvčím, který s nimi má trpělivost. Sami dokážou vyjádřit pouze základní myšlenky o své osobě slovními spojeními, která znají ze studia. Student na této úrovni dokáže napsat stručný jednoduchý text na pohlednici, například pozdrav z dovolené. Umí vyplnit formuláře obsahující základní osobní údaje.',
	A2: `Rozumí frázím a běžnější slovní zásobě vztahující se k oblastem, které se ho bezprostředně týkají (např. základní informace o jeho rodině a přátelích, o jeho volném čase, nakupování, místopisu, zaměstnání). Umí číst krátké jednoduché texty a v nich vyhledat konkrétní předvídatelné informace. Orientuje se v jednoduchých každodenních materiálech, např. v inzerátech, prospektech, jídelních lístcích a jízdních řádech.
	Dokáže komunikovat v jednoduchých, často se opakujících situacích s předvídatelnou výměnou informací o známých tématech a činnostech. Zvládne krátkou společenskou konverzaci a napsat jednoduchý osobní dopis.`,
	B1: `Rozumí i delším upraveným textům, které obsahují slovní zásobu často užívanou v každodenním životě nebo které  se vztahují k jeho práci a světu okolo. Je schopen na poměrně ucelené bázi pojmenovat většinu základních věcí a pocitů v každodenním životě, to včetně základních abstraktních výrazů.
	Umí si poradit s většinou situací, které mohou nastat při cestování. Dokáže se bez přípravy zapojit do hovoru o tématech, která jsou mu známá, o něž se zajímá nebo která se týkají každodenního života. Dokáže vyprávět příběh nebo v hrubých rysech přiblížit obsah knihy či filmu a vylíčit své reakce, popsat zážitky a události, sny, naděje a touhy a stručně zdůvodnit a vysvětlit své názory a plány.
	Umí vytvořit jednoduchý souvislý, logicky strukturovaný text o tématech, která dobře zná nebo která ho osobně zajímají, a funkčně využívat prostředky textové návaznosti.`,
	B2: `Rozumí hlavním myšlenkám autentických textů s konkrétními i abstraktními náměty, včetně odborné diskuse o oboru své specializace. Dokáže sledovat delší přednášku, v zásadě rozumí jednodušším filmům a seriálům v originálním znění, se základním porozuměním dokáže číst texty současné prózy.
	Dokáže se dorozumět plynule a spontánně s rodilými mluvčími, a to bez většího úsilí na obou stranách.
	Umí sestavit podrobnější, srozumitelný text o poměrně širokém okruhu témat, vysvětlit a obhájit stanovisko k aktuálním problémům, zdůvodnit výhody a nevýhody různých možností.`,
	C1: `Zkušený uživatel, který rozumí širokému okruhu náročných delších textů, a to i z oblasti beletrie. Dokáže rozpoznat implicitní významy v textu ("číst mezi řádky") a ocenit styl daného autora. Vyjadřuje se plynule a spontánně, bez příliš zjevného hledání výrazů, a dokáže jazyk účinně používat pro společenské, studijní i pracovní účely.
	Umí vytvořit jasný, dobře kompozičně vystavěný, podrobný text o složitých tématech, uvědoměle používat zásady výstavby textu, spojovací výrazy a prostředky vyjadřující vazby v textu.`,
	C2: `Profesionální uživatel, snadno rozumí prakticky všemu, co slyší nebo čte. Umí se vyjadřovat zcela spontánně, velice plynule a přesně, přičemž rozlišuje jemnější významové odstíny i ve složitějších situacích. Dokáže vytvořit složité, precizně slohově i myšlenkově strukturované texty, souvisle je prezentovat a vést o nich polemiku.`,
};

export const FORM_LABELS: FormLabel[] = [
	'CONTACTED',
	'REJECTED',
	'CONTACT_LATER',
];

export const FormLabelText: Record<FormLabel, string> = {
	CONTACTED: 'Kontaktován',
	REJECTED: 'Zamítnut',
	CONTACT_LATER: 'Kontaktovat později',
};

export const NotificationTypes = MakeTuple(
	'Objednávka zamítnuta',
	'Objednávka přijata',
	'Nová objednávka ke schválení',
	'Inzerát končí za 7 dní',
	'Inzerát končí zítra',
	'Inzerát byl deaktivován',
	'Docházející odkrytí studentů',
	'Licence končí za jeden měsíc',
	'Licence končí za dva týdny',
	'Licence firmy končí za dva týdny',
	'Licence končí za jeden týden',
	'Nezaplacená objednávka',
	'Nová reakce na inzerát',
	'Firma byla schválena',
	'Notifikace s vlastním obsahem',
	'Registrace firmy do k+ portálu',
	'Registrace nové firmy do k+ portálu',
	'Registrace absolventa do k+ portálu',
	'Nový inzerát sledované firmy',
	'Nový inzerát',
	'Nový návrh tvrdé dovednosti',
	'Schválení návrhu tvrdé dovednosti',
	'Zamítnutí návrhu tvrdé dovednosti',
	'Odpověď na inzerát přečtena',
	'Ukončení přístupu do EDIOSNU',
	'Odebrání tvrdé dovednosti z vašeho inzerátu',
	'Odebrání tvrdé dovednosti z vašeho profilu',
	'k+ portál | Vygenerovány nové statistiky',
);
export type NotificationTypeVariant = typeof NotificationTypes[number];

export const NotificationColors: Record<NotificationTypeVariant, string> = {
	'Objednávka zamítnuta': 'error',
	'Objednávka přijata': 'primary',
	'Nová objednávka ke schválení': 'primary',
	'Inzerát končí za 7 dní': 'warning',
	'Inzerát končí zítra': 'warning',
	'Inzerát byl deaktivován': 'error',
	'Docházející odkrytí studentů': 'warning',
	'Licence končí za jeden měsíc': 'warning',
	'Licence končí za dva týdny': 'warning',
	'Licence firmy končí za dva týdny': 'warning',
	'Licence končí za jeden týden': 'warning',
	'Nezaplacená objednávka': 'error',
	'Nová reakce na inzerát': 'primary',
	'Firma byla schválena': 'primary',
	'Notifikace s vlastním obsahem': 'secondary',
	'Registrace firmy do k+ portálu': 'secondary',
	'Registrace nové firmy do k+ portálu': 'secondary',
	'Registrace absolventa do k+ portálu': 'secondary',
	'Nový inzerát sledované firmy': 'primary',
	'Nový inzerát': 'primary',
	'Nový návrh tvrdé dovednosti': 'primary',
	'Schválení návrhu tvrdé dovednosti': 'primary',
	'Zamítnutí návrhu tvrdé dovednosti': 'error',
	'Odpověď na inzerát přečtena': 'primary',
	'Ukončení přístupu do EDIOSNU': 'error',
	'Odebrání tvrdé dovednosti z vašeho inzerátu': 'error',
	'Odebrání tvrdé dovednosti z vašeho profilu': 'error',
	'k+ portál | Vygenerovány nové statistiky': 'primary',
};

export const MAXI_LICENSE_ID = '62eaaff9-86a1-44d3-943f-95216ebb7c4a';
export const MIDI_LICENSE_ID = '6a5982be-073c-4c78-bae4-ed90411c135e';
export const MINI_LICENSE_ID = 'ff051467-253c-4198-a6cd-fb3c3ca4faca';
// only admin sees this product
export const UNLIMITED_LICENSE_ID = 'dccf2843-fab4-4fec-b4ce-6b631f3a5155';

export const MAXI_OFFERS_COUNT_TEXT = '20 + 5';
export const MIDI_OFFERS_COUNT_TEXT = '3 + 6';

export const MAXI_OFFERS_COUNT_JSX = (
	<Text as="span">
		20{' '}
		<Text as="span" color="primary">
			+
		</Text>{' '}
		5{' '}
	</Text>
);

export const MIDI_OFFERS_COUNT_JSX = (
	<Text as="span">
		6{' '}
		<Text as="span" color="primary">
			+
		</Text>{' '}
		3{' '}
	</Text>
);

export const ModelStudentID: Record<'DEV' | 'TEST' | 'PROD', string> = {
	DEV: 'STU_000121',
	TEST: 'STU_000121',
	PROD: 'STU_003156',
};

export const ModelCompanyID: Record<'DEV' | 'TEST' | 'PROD', string> = {
	DEV: 'b84fe24c-5508-4846-a19e-f34349cdb11e',
	TEST: 'b84fe24c-5508-4846-a19e-f34349cdb11e',
	PROD: 'a241fdf5-97d5-4bfd-b1ff-d89fc5220927',
};

export const FORCED_PROFILE_EDIT_FORM = '/introduction-form';
export const EMBED_URL = '/embed/offers';
export const POPUP_REDICERT_PATH = '/popup-redirect';

// these titles cant be used in user defined desc. pages.
export const COMPANY_DESCRIPTION_PAGES_REQ_TITLES = [
	'Jak to u nás vypadá',
	'Jak u nás vypadá přijímací proces a onboarding',
];

export const COMPANY_AID_EMAIL_MINIMUM_DAYS_GAP = 7;

/**
 * maximalny pocet fotografii  v galerii nabidky
 */
export const MAX_PHOTOS_IN_OFFER = 3;
